import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import Flex from "../containers/flex";
import Text from "../typography/text";
import Image from "../globals/image";
import LinkButton from "../links/linkButton";
import ComingSoon from "../globals/comingSoon";
import Heading from "../typography/heading";
import Button from "../buttons/button";
import FeatureList from "../globals/featureList";
import FeatureListFooter from "./featureListFooter";

const Root = styled(Flex)`
  flex-direction: column;
  padding: 20px 30px;
  position: relative;
  background-color: ${p => p.theme.grey};
  border-radius: ${p => p.theme.borderRadiusSqBtn};
  transition: ${p => p.theme.contentFade};

  span {
    display: block;
    text-align: center;
    color: ${p => p.theme.greyDark};
    font-size: ${p => p.theme.rem16};
    margin-bottom: 10px;

    :first-of-type {
      color: ${p => p.theme.blueDark};
      font-size: ${p => p.theme.rem24};
      margin-bottom: 25px;
    }
    :last-of-type {
      margin-bottom: 30px;
    }
  }

  h2 {
    font-size: ${p => p.theme.rem24};
    margin-bottom: 5px;
  }

  img {
    margin: auto;
  }

  @media (min-width: ${p => p.theme.screenL}) {
    padding: 30px 40px 60px 40px;
    max-width: 370px;
  }
`;

const HideOnDesktop = styled.div`
  text-align: left;

  ul {
    background-color: white;

    li:last-child {
      border-bottom: none;
    }
  }

  @media (min-width: ${p => p.theme.screenL}) {
    display: none;
  }
`;

const FeaturesButton = styled(Button)`
  &:hover {
    color: ${p => p.theme.blueDark};
    border-color: ${p => p.theme.blueDark};
  },
`;

const MainButton = styled(LinkButton)`
  width: 100%;
  margin-bottom: 30px;
  padding: 14px 24px;
`;

const Plan = ({
  imgData,
  plan,
  prices,
  onFeatureButtonClick,
  theme,
  ...rest
}) => {
  const fluidImage = imgData[plan.imageName].childImageSharp.fluid;
  const [monthlyPrice, quarterlyPrice, triAnnuallyPrice, yearlyPrice] = prices;

  const getPrices = (planType) => {
    if (planType === `Parent Portal`) {
      return (
        <>
          <span>{monthlyPrice} / child, per month</span>
          {triAnnuallyPrice.length > 1 && (
            <span>
              {`${triAnnuallyPrice} billed tri-annually`}
            </span>
          )}
          {quarterlyPrice.length > 1 && (
            <span>
              {`${quarterlyPrice} billed quarterly`}
            </span>
          )}
          <span>{yearlyPrice} billed annually</span>
        </>
      );
    }

    // Else..
    return (
      <>
        <span>{plan.price}</span>
        <span>{plan.priceInfo}</span>
      </>
    );
  };
  return (
    <Root {...rest}>
      <Image
        fluid={fluidImage}
        alt={plan.imageAlt}
        width="100%"
        maxWidth={[`100px`, null, null, `140px`]}
        mx="auto"
        mb={[25, null, null, 30]}
        mt={[`-60px`, null, null, `-100px`]}
      />

      {!plan.released && <ComingSoon right="20px" top="20px" />}

      <Heading as="h2" textAlign="center">
        {plan.type}
      </Heading>

      {getPrices(plan.type)}

      {!plan.released ? (
        <MainButton as="div" fontSize={theme.rem18} bg={theme[plan.buttonColor]} mb="30px">
          {plan.buttonText}
        </MainButton>
      ) : (
        <MainButton as="a" fontSize={theme.rem18} href={plan.buttonHref} bg={theme[plan.buttonColor]}>
          {plan.buttonText}
        </MainButton>
      )}

      <Text
        lineHeight="28px"
        textAlign={[`left`, null, null, `center`]}
        mx="0"
        mb="30px"
      >
        {plan.planInfo}
      </Text>
      
      {/* Shows on Desktop */}
      <FeaturesButton
        display={[`none`, null, null, `block`]}
        fontSize={theme.rem16}
        maxWidth="156px"
        border={`2px solid ${theme.greyLight}`}
        borderRadius={theme.borderRadiusSqBtn}
        color={theme.greyDark}
        p={theme.btnPadS}
        mx="auto"
        mt="auto"
        onClick={() => onFeatureButtonClick(plan.type)}
      >
        See all features
      </FeaturesButton>

      {/* Shows on Mobile */}
      <HideOnDesktop>
        <FeatureList features={plan.features}>
          {/* FeatureListFooter is added as the last LI in the FeatureList, via children */}
          <FeatureListFooter plan={plan} monthlyPrice={monthlyPrice} />
        </FeatureList>
      </HideOnDesktop>
    </Root>
  );
};

Plan.propTypes = {
  imgData: PropTypes.object.isRequired,
  plan: PropTypes.object.isRequired,
  prices: PropTypes.array.isRequired,
  onFeatureButtonClick: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Plan);
