import handleErrors from "../utils/handleErrors";
import { getApiPath } from "../utils/getPath";

export async function getRegionData() {
  try {
    const res = await fetch(`https://extreme-ip-lookup.com/json/`, {
      method: `GET`,
    });

    handleErrors(await res);
    return await res.json();
  } catch (e) {
    throw e;
  }
}

export async function getCountryPrices(intCountryCode, referrer) {
  // referrer passed to the BE cannot be null or undefined, has to be an empty/filled string
  let countryCode = intCountryCode;
  if ([`GB`, `US`].indexOf(intCountryCode) === -1) {
    // default country to US
    countryCode = `US`;
  }
  try {
    const res = await fetch(
      `${getApiPath()}api/payment/subscriptions/available/public?int_country_code=${countryCode}&referrer=${referrer}`,
      {
        method: `GET`,
      },
    );

    handleErrors(await res);
    return await res.json();
  } catch (e) {
    throw e;
  }
}
