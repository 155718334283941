import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Text from "../typography/text";

const Root = styled.ul`
  position: relative;
  padding: 0;
  margin: auto;
  max-width: 992px;
  z-index: 5;

  li {
    padding: 16px 20px;
    border-left: 1px solid ${p => p.theme.grey};
    border-right: 1px solid ${p => p.theme.grey};
    margin: 0;
    text-align: left;
    font-size: ${p => p.theme.rem16};
    line-height: 20px;
    color: ${p => p.theme.greyDark};
    border-bottom: 1px solid ${p => p.theme.grey};

    &:first-of-type {
      border-top: 1px solid ${p => p.theme.grey};
    }

    &:nth-child(odd) {
      background-color: ${p => p.theme.white};
    }
    &:nth-child(even) {
      background-color: ${p => p.theme.greyLightest};
    }

    @media (min-width: ${p => p.theme.screenL}) {
      padding: 26px 30px;
      font-size: ${p => p.theme.rem20};
      line-height: 38px;
    }
  }
`;

// children is used for extending list with custom input (use <li> elements)
const FeatureList = ({ features, children }) => {
  return (
    <Root>
      {features.map((feat, index) => {
        return (
          <Text as="li" key={index}>
            {feat}
          </Text>
        );
      })}
      {children}
    </Root>
  );
};

FeatureList.propTypes = {
  features: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  children: PropTypes.object,
};

export default FeatureList;
