export const paymentPlans = [
  {
    id: 1,
    imageName: `parentPlan`,
    imageAlt: `Illustration of parent user`,
    type: `Parent Portal`,
    price: `$3.99 / child per month`,
    priceInfo: `$34.99 billed annually`,
    planInfo: `Add family members to your portal to give them their own accounts. Track their progress and latest scores. Securely connect with their Coaches and Teams.`,
    buttonText: `Try for free today`,
    buttonHref: `https://app.toptekkers.com`,
    buttonColor: `green`,
    features: [
      `Choose the difficulty rating for your child`,
      `Track your child's activity and scores `,
      `Manage your child's account details to keep them secure`,
      `Securely connect with your child's coach`,
    ],
    featurePlanInfo: ` per child, per month`,
    released: true,
  },
  {
    id: 2,
    imageName: `coachPlan`,
    imageAlt: `Illustration of Coach Crane`,
    type: `Coach Portal`,
    price: `FREE`,
    priceInfo: `TopTekkers is FREE for coaches`,
    planInfo: `Create teams and manage your players.  Connect with Parents and add Players to your team to set training plans`,
    buttonText: `Contact TopTekkers Account Managers`,
    buttonHref: `/contact`,
    buttonColor: `blue`,
    features: [
      `Securely connect with players you coach via their parent`,
      `Create teams quickly and easily to manage skill development`,
      `Tailor skill development for players by setting individual training plans`,
      `Track your players' scores, trophies and activity`,
    ],
    featurePlanInfo: `FREE`,
    released: true,
  },
  {
    id: 3,
    imageName: `clubPlan`,
    imageAlt: `TopTekkers logo mounted onto a shield.`,
    type: `Clubs`,
    price: `Tailor your cost`,
    priceInfo: `Contact us for a tailored quote for your club / organisation`,
    planInfo: `Large-scale licensing for football clubs and organisations.`,
    buttonText: `Contact TopTekkers`,
    buttonHref: `/contact`,
    buttonColor: `yellow`,
    features: [
      `Give players, parents and coaches at your club all the benefits of TopTekkers at a discounted rate`,
      `Flexible license model can cater for clubs of any size`,
      `Fantastic onboarding support to help you enjoy the benefits of TopTekkers as quickly and easily as possible`,
      `Ongoing support from TopTekkers to help you continue to maximise the impact for the club`,
    ],
    featurePlanInfo: `Tailor your cost`,
    released: true,
  },
];
