import React from "react";
import styled from "styled-components";
import { Absolute } from "../containers/position";

const Root = styled(Absolute)`
  border-radius: 50%;
  padding: 16px 8px;
  text-align: center;

  color: ${p => p.theme.white};
  background-color: ${p => p.theme.purple};
  font-size: 10px;
  font-family: ${p => p.theme.headingFont};
`;

const ComingSoon = ({ ...rest }) => {
  return (
    <Root {...rest}>
      Coming <br /> soon
    </Root>
  );
};

export default ComingSoon;
