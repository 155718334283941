import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";

import LinkButton from "../links/linkButton";

const Root = styled.li`
  @media (min-width: ${p => p.theme.screenL}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const PlanInfoWrap = styled.div`
  @media (min-width: ${p => p.theme.screenL}) {
    div:first-of-type {
      margin-right: 20px;
    }
    > * {
      display: inline-block;
    }
  }
`;

const PlanType = styled.div`
  font-family: ${p => p.theme.headingFont};
  color: ${p => p.theme.blueDark};
  margin-bottom: 10px;
  font-size: 20px;

  @media (min-width: ${p => p.theme.screenL}) {
    margin-bottom: 0;
  }
`;
const PlanInfo = styled.div`
  margin-bottom: 15px;

  @media (min-width: ${p => p.theme.screenL}) {
    margin-bottom: 0;
  }
`;

const MainButton = styled(LinkButton)`
  width: 100%;
  line-height: 20px;
  padding: 14px 26px;

  @media (min-width: ${p => p.theme.screenL}) {
    width: auto;
  }
`;

const FeatureListFooter = ({ plan, monthlyPrice, theme }) => {
  return (
    <Root>
      <PlanInfoWrap>
        <PlanType>{plan.type}</PlanType>
        {plan.type === `Parent Portal` ? (
          <>
            <PlanInfo>
              {monthlyPrice}
              {plan.featurePlanInfo}
            </PlanInfo>
          </>
        ) : (
          <>
            <PlanInfo> {plan.featurePlanInfo}</PlanInfo>
          </>
        )}
      </PlanInfoWrap>

      {!plan.released ? (
        <MainButton as="div" fontSize={theme.rem18} bg={theme[plan.buttonColor]}>
          {plan.buttonText}
        </MainButton>
      ) : (
        <MainButton as="a" fontSize={theme.rem18} href={plan.buttonHref} bg={theme[plan.buttonColor]}>
          {plan.buttonText}
        </MainButton>
      )}
    </Root>
  );
};

FeatureListFooter.propTypes = {
  plan: PropTypes.object.isRequired,
  monthlyPrice: PropTypes.string,
  theme: PropTypes.object.isRequired,
};

export default withTheme(FeatureListFooter);
