import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";
import Cookies from "js-cookie";

import SEO from "../components/globals/seo";
import Section from "../components/containers/section";
import Box from "../components/containers/box";
import Layout from "../components/containers/layout";
import Container from "../components/containers/container";
import Heading from "../components/typography/heading";
import Text from "../components/typography/text";
import PageTitle from "../components/typography/pageTitle";
import Plan from "../components/pricingPage/plan";
import MobilePayPlanButtons from "../components/pricingPage/mobilePayPlanButtons";
import DesktopPlansFeatureButtons from "../components/pricingPage/desktopPlansFeatureButtons";
import Circle from "../components/globals/circle";
import FreeTrialBanner from "../components/banners/freeTrialBanner";
import MaxWidthAbsolute from "../components/containers/maxWidthAbsolute";
import FeatureList from "../components/globals/featureList";
import FeatureListFooter from "../components/pricingPage/featureListFooter";
import Grid from "../components/containers/grid";
import { getRegionData, getCountryPrices } from "../actions/paymentActions";
import { Relative } from "../components/containers/position";
import { paymentPlans } from "../utils/content/pricing";

const PlanStacked = styled(Plan)`
  position: absolute;
  opacity: 0;
  top: 0;
  width: 100%;
  visibility: hidden;

  ${p => p.active === true && ` 
    position: relative;
    opacity: 1;
    visibility: visible;
  `}
`;

const DesktopFeaturesWrap = styled.div`
  li {
    &:last-of-type {
      border-top: 1px solid ${p => p.theme.grey};
      margin-top: 40px;
    }
  }
`;

const PricingPage = ({ data, theme }) => {
  // Store prices
  const [monthlyPrice, setMonthlyPrice] = useState(``);
  const [quarterlyPrice, setQuarterlyPrice] = useState(``);
  const [triAnnuallyPrice, setTriAnnuallyPrice] = useState(``);
  const [yearlyPrice, setYearlyPrice] = useState(``);
  // Group prices
  const prices = [monthlyPrice, quarterlyPrice, triAnnuallyPrice, yearlyPrice];
  // Track an active plan for mobile layout
  const [activePlan, setActivePlan] = useState(paymentPlans[0]);

  const handlePlanChange = (type) => {
    // 1. Check if active plan type matches argument
    if (activePlan.type !== type) {
      // 2. If not loop through plans
      paymentPlans.map((plan) => {
        // 3. Find the plan that matchces the active plan type
        if (plan.type === type) {
          // 4. Set it to active plan
          setActivePlan(plan);
        }
      });
    }
  };

  // 1. When user clicks feature button
  const handleFeatureButtonClick = (plan) => {
    // 2. Set clicked plan
    handlePlanChange(plan);
    // 3. scroll features into view
    const location = document.getElementById(`features`);
    location.scrollIntoView({ behavior: `smooth` });
  };

  // Using the passed currency return the relevant symbol
  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case `GBP`:
        return `£`;
      case `USD`:
        return `US$`;
      case `EUR`:
        return `€`;
      case `AUD`:
        return `A$`;
      case `CAD`:
        return `CA$`;
      default:
        return `£`;
    }
  };

  const getReferrer = () => {
    const cookieReferrer = Cookies.get(`ttreferrer`);
    const urlString = window.location.href;
    const url = new URL(urlString);
    const paramReferrer = url.searchParams.get(`referrer`);

    if (cookieReferrer) {
      return cookieReferrer;
    }
    if (paramReferrer) {
      return paramReferrer;
    }

    return ``;
  };

  // Prep pricing variables
  let currencyCode = ``;
  let currencySymbol = ``;
  let referrer = ``;
  let urlReferrer = ``;
  // If window exists set referrer
  if (typeof window !== `undefined`) {
    urlReferrer = getReferrer();
  }

  // 1. useEffect used as componentDidMount(), waiting for data
  useEffect(() => {
    // 2. Fire API to get countryCode ready to feed prices API
    getRegionData()
      .then((res) => {
        // 3. Grab countryCode and use it to get correct currency symbol
        currencyCode = res.countryCode;

        // 4. If ttreferrer exists in URL then set it to referrer, else set empty string (mustn't be null)
        if (urlReferrer === null) {
          referrer = ``;
        } else {
          referrer = urlReferrer;
        }
      })
      .then(() => {
        // 5. Use currencyCode and referrer to get pricing info
        getCountryPrices(currencyCode, referrer).then((res) => {
          // 6. Loop returned data to find monthly/yearly objects with active set to true
          const { monthly, yearly } = res.data
          setMonthlyPrice(`${monthly.symbol}${monthly.price}`);
          setYearlyPrice(`${yearly.symbol}${yearly.price}`);
        });
      });
  }, []);

  return (
    <Layout>
      <SEO
        title="Pricing"
        description="Pricing for individual or multiple accounts on TopTekkers"
        path="/pricing"
      />

      <Section>
        <Container textAlign={[null, `center`]} zIndex={5}>
          <Box
            maxWidth={[`420px`, null, `480px`, `680px`]}
            mb={[0, null, null, `170px`]}
            mx="auto"
          >
            <PageTitle textAlign={[`left`, `center`]}>PRICING</PageTitle>

            <Heading as="h1">
              Find a payment plan that suits you
            </Heading>

            <Text
              maxWidth="420px"
              fontSize={[18, null, 24]}
              mx={[0, `auto`]}
              mb={30}
              color={theme.blueDark}
            >
              Choose the right plan for you, or try for <b>FREE</b>
            </Text>

            {/* PLANS: Shows on Mobile, uses activePlan */}
            <Relative display={[`block`, null, null, `none`]}>
              <MobilePayPlanButtons
                onClick={plan => handlePlanChange(plan)}
                activePlanType={activePlan.type}
              />
              
              <Relative>
                <PlanStacked
                  active={activePlan.type === paymentPlans[0].type}
                  plan={paymentPlans[0]}
                  prices={prices}
                  imgData={data}
                  onFeatureButtonClick={plan => handleFeatureButtonClick(plan)}
                />
                <PlanStacked
                  active={activePlan.type === paymentPlans[1].type}
                  plan={paymentPlans[1]}
                  prices={prices}
                  imgData={data}
                  onFeatureButtonClick={plan => handleFeatureButtonClick(plan)}
                />

                <PlanStacked
                  active={activePlan.type === paymentPlans[2].type}
                  plan={paymentPlans[2]}
                  prices={prices}
                  imgData={data}
                  onFeatureButtonClick={plan => handleFeatureButtonClick(plan)}
                />
              </Relative>
            </Relative>
          </Box>

          {/* PLANS: Shows on Desktop, sets individual plans (ie plan 1/2/3) */}
          <Grid
            display={[`none`, null, null, `grid`]}
            alignItems="top"
          >
            <Plan
              gridColumn="1 / span 4"
              plan={paymentPlans[0]}
              prices={prices}
              imgData={data}
              onFeatureButtonClick={plan => handleFeatureButtonClick(plan)}
            />
            <Plan
              gridColumn="5 / span 4"
              plan={paymentPlans[1]}
              prices={prices}
              imgData={data}
              onFeatureButtonClick={plan => handleFeatureButtonClick(plan)}
            />

            <Plan
              gridColumn="9 / span 4"
              plan={paymentPlans[2]}
              prices={prices}
              imgData={data}
              onFeatureButtonClick={plan => handleFeatureButtonClick(plan)}
            />
          </Grid>
        </Container>

        <MaxWidthAbsolute>
          <Circle
            grow
            bgColor={theme.orange}
            top={100}
            left={[-30, null, -46, -90]}
          />
          <Circle
            grow
            bgColor={theme.pink}
            top={[-66, null, -90, -166]}
            right={(`20%`, null, `10%`)}
          />
        </MaxWidthAbsolute>
      </Section>

      {/* FEATURES: Shows on Desktop */}
      <Section className="hideOnTablet" id="features">
        <Container>
          <Heading
            as="h3"
            mb={[20, null, 50]}
            mx="auto"
            textAlign={[null, `center`]}
          >
            Features
          </Heading>

          <DesktopFeaturesWrap>
            <DesktopPlansFeatureButtons
              onClick={planType => handlePlanChange(planType)}
              activePlanType={activePlan.type}
            />

            <FeatureList features={activePlan.features}>
              {/* FeatureListFooter is added as the last LI in the FeatureList, via children */}
              <FeatureListFooter
                plan={activePlan}
                monthlyPrice={monthlyPrice}
              />
            </FeatureList>
          </DesktopFeaturesWrap>

          <Circle
            grow
            bgColor={theme.purple}
            top={`30%`}
            right={[null, null, 10, 50]}
          />
          <Circle
            grow
            bgColor={theme.red}
            top={`50%`}
            left={[-30, null, -46, -90]}
          />
        </Container>
      </Section>

      <FreeTrialBanner display={[`none`, null, null, `block`]} />
    </Layout>
  );
};

export const query = graphql`
  query {
    parentPlan: file(relativePath: { eq: "images/Parent-Illustration.png" }) {
      ...imageFragmentPricingPlanPic
    }
    coachPlan: file(relativePath: { eq: "images/Coach-Illustration.png" }) {
      ...imageFragmentPricingPlanPic
    }
    clubPlan: file(relativePath: { eq: "images/Club-Illustration.png" }) {
      ...imageFragmentPricingPlanPic
    }
  }
`;

PricingPage.propTypes = {
  theme: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default withTheme(PricingPage);
